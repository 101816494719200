exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ajuda-js": () => import("./../../../src/pages/ajuda.js" /* webpackChunkName: "component---src-pages-ajuda-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invite-[id]-js": () => import("./../../../src/pages/invite/[id].js" /* webpackChunkName: "component---src-pages-invite-[id]-js" */),
  "component---src-pages-privacidade-js": () => import("./../../../src/pages/privacidade.js" /* webpackChunkName: "component---src-pages-privacidade-js" */),
  "component---src-pages-remover-conta-index-js": () => import("./../../../src/pages/remover/conta/index.js" /* webpackChunkName: "component---src-pages-remover-conta-index-js" */),
  "component---src-pages-termos-js": () => import("./../../../src/pages/termos.js" /* webpackChunkName: "component---src-pages-termos-js" */)
}

